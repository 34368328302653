// components/GawdPlayer.js
import styles from './GawdPlayer.module.css'

import { useRef, useEffect } from 'react'
import { Player, PlayerProps } from 'gawd-player'
import { StereoMode } from 'three-spatial-viewer'

import 'web/lib/string-utils'

type GawdPlayerProps = {
  hash?: string
  configUrl?: string
  video?: boolean
  toggleModes?: boolean
  className?: string
  data?: any
  children?: any
}

const GawdPlayer = ({ hash, configUrl, video, toggleModes, className, data, children }: GawdPlayerProps) => {
  const viewer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const playerProps = new PlayerProps()
    playerProps.container = viewer.current // fun type hack
    playerProps.url = `https://ipfs.io/ipfs/${hash}`

    if (configUrl) {
      playerProps.url = configUrl
    }
    else if (data?.tokenURI) {
      playerProps.url = data.tokenURI
    }

    playerProps.spatialProps.stereoMode = StereoMode.OFF

    // Passing in all the data will skip the call to the json config
    if (data) {
      playerProps.gawdData = data

      // Rewrite all the URLs to use gateway
      playerProps.gawdData.assets && playerProps.gawdData.assets.forEach((asset) => {
        asset.url = asset.url.toGatewayUrl()
      })
    }

    // Enable click to toggle between display modes
    if (toggleModes) {
      playerProps.toggleModes = true
    }

    // AKA: Default to video on load
    if (video) {
      playerProps.defaultAsset = playerProps.defaultMobileAsset
    }

    const player = new Player(playerProps)
    return () => {
      player.dispose()
    }
  }, [data?.id])

  return (
    <div className={`${styles.gawdViewerWrapper} ${className}`}>
      {children}
      <div ref={viewer} className={`${styles.gawdViewer}`}></div>
    </div>
  )
}
export default GawdPlayer
