import classNames from 'classnames'
import styles from './TamaGawdtchi.module.css'
import { useCallback, useContext, useEffect, useState } from 'react'
import { GawchiAction, TamaGawdtchiManager } from 'web/lib/TamaGawdtchiManager'
import { useSWRConfig } from 'swr'
import { CacheKey } from 'web/lib/cache-keys'
import { useWeb3React } from '@web3-react/core'
import { StoreContext } from 'web/lib/store'
import { NonceContext } from 'web/lib/nonce-provider'

type GawchButtonProp = {
  gawdtchi: TamaGawdtchiManager,
  action: GawchiAction,
  onMouseEnter: Function,
  onMouseLeave: Function
}

const TamaGawdtchiButton = ({ gawdtchi, action, onMouseEnter, onMouseLeave }: GawchButtonProp) => {
  const { mutate } = useSWRConfig()
  const { account, active } = useWeb3React()

  const {
    connectWalletModal: [isOpen, dispatchModalState],
  } = useContext(StoreContext)

  const [nonceManager, dispatchNonce] = useContext(NonceContext)

  let actionValue = 0
  let actionLabel = ""

  if (gawdtchi != null) {
    actionValue = gawdtchi.getCooldownByAction(action)
    actionLabel = gawdtchi.getLabelByAction(action)
  }

  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(actionValue == 0 && !gawdtchi?.getActionState(action).loading && !nonceManager.isFetching)
  }, [gawdtchi.gawdId(), actionValue, nonceManager.isFetching])

  // Keyboard left/right events
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key == "q" && action == GawchiAction.Worship) {
      actionClick()
    }
    else if (e.key == "w" && action == GawchiAction.Marvel) {
      actionClick()
    }
    else if (e.key == "e" && action == GawchiAction.Sacrifice) {
      actionClick()
    }
    else if (e.key == "r" && action == GawchiAction.Cower) {
      actionClick()
    }
    else if (e.key == "t" && action == GawchiAction.Resurrect) {
      actionClick()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    }
  }, [gawdtchi.gawdId(), action])

  const actionClick = useCallback(async () => {
    if (!active) {
      openWalletModal()
      return
    }
    await gawdtchi.action(action)
    if (!gawdtchi.gawdResponseError) {
      mutate(CacheKey.rebaserData(account))
    }
    else if (isActive) {
      // try busting gawchi cache if there was an error
      mutate(CacheKey.gawchiStats(gawdtchi.gawdId()))
    }
  }, [account, gawdtchi.gawdId(), action, isActive])

  // @param {number} percentage as an integer (subject to change)
  // @returns {svg}
  const renderProgressSVG = (percentage) => {
    percentage *= 100

    const percentageComplete = `${percentage}`
    const percentageRemaining = `${100 - percentage}`

    // How to make donut pie chart svg
    // https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
    // https://codepen.io/janverstr/pen/PvPoaV
    return (<svg width="100%" height="100%" viewBox="0 0 34 34" className="donut">
      <circle className={styles.donutHole} cx="17" cy="17" r="15.91549430918954" ></circle>
      <circle className={styles.donutRing} cx="17" cy="17" r="15.91549430918954" fill="transparent" strokeWidth="1.5"></circle>
      <circle className={styles.donutSegment} cx="17" cy="17" r="15.91549430918954" fill="transparent" strokeWidth="1.5" strokeDasharray={`${percentageComplete} ${percentageRemaining}`} strokeDashoffset="25"></circle>
    </svg>)
  }

  function closeWalletModal() {
    dispatchModalState(['CLOSE'])
  }

  async function openWalletModal() {
    dispatchModalState(['OPEN'])
  }

  return (
    <button
      className={classNames(
        styles.roundButton,
        isActive ? styles.active : styles.inactive,
        isActive && action == GawchiAction.Resurrect ? styles.pulse : '',
        gawdtchi?.getActionState(action).loading ? styles.loading : '',
      )}
      onClick={actionClick}
      onMouseEnter={() => { onMouseEnter('enter', action, gawdtchi) }}
      onMouseLeave={() => { onMouseLeave('leave', action, gawdtchi) }}
    >
      {renderProgressSVG(actionValue)}
      <span className={styles.action}>
        {actionLabel}
      </span>
      <span className={styles.loader}>
        <img src="/images/gawdtchi-loader.gif" />
      </span>
    </button>
  )
}
export default TamaGawdtchiButton