import 'web/lib/string-utils'
import { useEffect, useState, useContext } from 'react'
import classNames from 'classnames'
import { GawchiAction, TamaGawdtchiManager } from 'web/lib/TamaGawdtchiManager'
import useTamagawdtchi from 'web/hooks/useTamagawdtchi'
import TamaGawdtchiButton from './TamaGawdtchiButton'
import usePoller from 'web/hooks/usePoller'
import useMyBoons from 'web/hooks/useMyBoons'
import usePolygonBlockTime from 'web/hooks/usePolygonBlockTime'
import useIsGawdOwner from 'web/hooks/useIsGawdOwner'
import Gawd from 'web/lib/gawd'
import useEnvironment from 'web/hooks/useEnvironment'

type TooltipData = {
  action: GawchiAction,
  gawchi: TamaGawdtchiManager
}

const TamaGawdtchi = ({ id }) => {
  const gawd = new Gawd({ id: parseInt(id) })
  const { isMainnetLive } = useEnvironment()

  let tamagawdtchi = useTamagawdtchi(gawd.id())

  const [error, setError] = useState<string>()
  const [showError, setErrorVisiblity] = useState<boolean>(false)
  const [toolTip, setToolTip] = useState<TooltipData>(null)

  const myBoons = useMyBoons()
  const isOwner = useIsGawdOwner(gawd)
  const isBoonActive = isOwner && myBoons.active.length > 0

  if (tamagawdtchi?.gawdResponseError && !showError) {
    setError(tamagawdtchi?.gawdResponseError)
    setErrorVisiblity(true)
    tamagawdtchi.gawdResponseError = null
    // Hide the error after 4 seconds
    setTimeout(() => {
      setErrorVisiblity(false)
    }, 4000)
  }

  const handleToolTip = (mouseEvent, action, gawdtchi) => {
    if (mouseEvent !== 'leave') {
      setToolTip({
        action: action,
        gawchi: gawdtchi
      })
    }
    else {
      setToolTip(null)
    }
  }

  // Update cooldown stats every second
  usePoller(1000)
  const secondsPerBlock = usePolygonBlockTime()
  const secondsRemaining = toolTip?.gawchi?.getCooldownSecondsRemainingByAction(toolTip?.action, secondsPerBlock)

  return (
    <div className="relative space-y-5 md:space-y-4">
      <div className="absolute m-auto -top-24 w-full text-center">
        {/* Pulsing red text (show when an action is rejected for a specific contract reason) */}
        {showError && (
          <span className="animate-fadeIn relative inline-block bg-red-500 rounded-lg text-white px-6 py-2">
            <span className="absolute inset-0 animate-ping inline-block bg-red-500 rounded-lg text-white px-6 py-2"></span>
            <span className="relative z-1">{error.ucfirst()}</span>
          </span>
        )}

        {/* white text on gray bg */}
        {toolTip && !showError && (
          <span className="animate-fadeIn relative inline-block bg-black bg-opacity-50 text-white-500 rounded-lg px-6 py-2">

            {secondsRemaining > 0 ?
              <span className="relative z-1">
                <b>{toolTip.action?.ucfirst()} Cooldown</b>
                <br />
                {secondsRemaining.timeForHumans()}
              </span>
              :
              <span className="relative z-1">
                {toolTip.action?.ucfirst()}
              </span>
            }
          </span>
        )}
      </div>

      {/* Gawdtchi Actions */}
      <div className="relative space-y-5 md:space-y-4 -top-4">
        <div className={classNames('flex items-center justify-center space-x-5 z-10 -mt-5 md:-mt-10')}>
          {tamagawdtchi && tamagawdtchi?.getAlive() && (
            <>
              <TamaGawdtchiButton gawdtchi={tamagawdtchi} action={GawchiAction.Worship} onMouseEnter={handleToolTip} onMouseLeave={handleToolTip} />
              <TamaGawdtchiButton gawdtchi={tamagawdtchi} action={GawchiAction.Marvel} onMouseEnter={handleToolTip} onMouseLeave={handleToolTip} />
              <TamaGawdtchiButton gawdtchi={tamagawdtchi} action={GawchiAction.Sacrifice} onMouseEnter={handleToolTip} onMouseLeave={handleToolTip} />
              <TamaGawdtchiButton gawdtchi={tamagawdtchi} action={GawchiAction.Cower} onMouseEnter={handleToolTip} onMouseLeave={handleToolTip} />
            </>
          )}

          {tamagawdtchi && !tamagawdtchi?.getAlive() && (
            <TamaGawdtchiButton gawdtchi={tamagawdtchi} action={GawchiAction.Resurrect} onMouseEnter={handleToolTip} onMouseLeave={handleToolTip} />
          )}
        </div>
        <div className="space-y-2 m-auto max-w-sm">
          {/* HEALTH BAR  */}
          <div className={classNames('relative rounded-lg bg-gray-800 h-8', { 'pulse-animate': false })}>
            {tamagawdtchi?.getHealth() > 0 && (
              <span
                className={classNames(
                  "absolute top-0 left-0 bottom-0 rounded-lg transition-all duration-700 ease-in-out",
                  isBoonActive ? 'bg-green-500' : 'bg-red-500'
                )}
                style={{ width: `${tamagawdtchi?.getHealth() * 100}%` }}
              >
                &nbsp;
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TamaGawdtchi
